import React from 'react';
import { navigate } from 'gatsby-link';

import { css, Theme } from '@emotion/react';
import Grid from '@mui/material/Grid';

import ContactMessage from '@/components/molecules/index/ContactMessage';
import LinkBox from '@/components/molecules/index/LinkBox';

const contactLinkBlockWrapper = (theme: Theme) => css({
  background: theme.palette.secondaryGradation.default,
  minHeight: '30vh',
});

const ContactLinkBlock: React.VFC = () => {
  return (
    <section>
      <Grid container css={contactLinkBlockWrapper}>
        <Grid item xs={12} md={8}>
          <ContactMessage />
        </Grid>
        <Grid item xs={12} md={4}>
          <LinkBox
            linkTitleText={['Contact']}
            linkButtonText="お問い合わせ"
            handleClick={() => navigate('/contact')}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default ContactLinkBlock;
