import React from 'react';

import { css } from '@emotion/react';
import Typography from '@mui/material/Typography';

type LinkTitleProps = {
  title: Array<string>;
}

const linkTitleStyles = css({
  color: '#ffffff',
  fontSize: '1.8rem',
});

const LinkTitle: React.VFC<LinkTitleProps> = (props) => {
  const { title } = props;

  return (
    <Typography align="center">
      {title.map(element => {
        return (
          <Typography css={linkTitleStyles} key={ element } component="span">
            { element }
          </Typography>
        );
      })}
    </Typography>
  );
};

export default LinkTitle;
