import React from 'react';

import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type LinkButtonProps = {
  buttonText: string;
  handleClick?: () => void;
};

const linkButtonStyles = css({
  color: '#ffffff',
  fontSize: '1.1rem',
  fontWeight: 600,
  border: '3px solid #ffffff',
  borderRadius: 0,
  padding: '1rem 2.4rem',
  '&:hover': {
    border: '3px solid #ffffff',
    backgroundColor: 'rgb(255, 255, 255, 0.1)',
  }
});

const LinkButton: React.VFC<LinkButtonProps> = (props) => {
  const { buttonText, handleClick } = props;

  return (
    <Button
      variant="outlined"
      css={linkButtonStyles}
      endIcon={<ArrowForwardIosIcon />}
      onClick={handleClick}
    >
      { buttonText }
    </Button>
  );
};

export default LinkButton;
