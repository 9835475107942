import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const contactMessageBox = (theme: Theme) => css({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const contactMessageContents = (theme: Theme) =>  css({
  width: '90%',
  margin: '3rem auto 0',
  [theme.breakpoints.up('md')]: {
    margin: '3rem auto',
  }
});

const contactMessageTitle = css({
  marginBottom: '1.5rem',
  color: '#ffffff',
});

const contactMessageText = css({
  color: '#ffffff',
});

const brAdjust = css({
  display: 'inline-block',
});

const ontactMessage: React.VFC = () => {
  return (
    <Box css={contactMessageBox}>
      <Box css={contactMessageContents}>
        <Typography variant="h2" css={contactMessageTitle}>
          <span css={brAdjust}>私たちは、お客様</span>
          <span css={brAdjust}>ひとりひとりに</span>
          <span css={brAdjust}>寄り添います</span>
        </Typography>
        <Typography css={contactMessageText}>
          <span css={brAdjust}>株式会社富士見工務店は、</span>
          <span css={brAdjust}>富士見市を中心に、リフォームや</span>
          <span css={brAdjust}>注文住宅の施工を承っております。</span>
          <br />
          <span css={brAdjust}>新築一戸建て、増築・改築（増改築）、</span>
          <span css={brAdjust}>改修・修繕工事、耐震補強工事にも</span>
          <span css={brAdjust}>ご対応しております。</span>
          <br />
          <span css={brAdjust}>新築・増改築・リフォーム等に関して、</span>
          <span css={brAdjust}>わからない点やご相談などございましたら、</span>
          <br />
          <span css={brAdjust}>お電話もしくはお問合せフォームから</span>
          <span css={brAdjust}>お気軽にご連絡ください。</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default ontactMessage;
