import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';

import LinkTitle from '@/components/atoms/common/LinkTitle';
import LinkButton from '@/components/atoms/common/LinkButton';

type LinkBoxProps = {
  backgroundColor?: 'primary' | 'secondary';
  linkTitleText: Array<string>;
  linkButtonText: string;
  handleClick: () => void;
};

const linkBoxStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '30vh',
  height: '100%',
  padding: '2.4rem 0'
});

const linkBoxContentWrapper = css({
  width: '80%',
});

const linkButtonStylesWraper = css({
  margin: '1.5rem auto',
  textAlign: 'center',
});

const primaryBackground = (theme: Theme) => css({
  background: theme.palette.primaryGradation.default,
});

const secondaryBackground = (theme: Theme) => css({
  background: theme.palette.secondaryGradation.default,
});

const LinkBox: React.VFC<LinkBoxProps> = (props) => {
  const { backgroundColor, linkTitleText, linkButtonText, handleClick } = props;

  return (
    <Box
      css={[
        linkBoxStyles,
        backgroundColor === 'primary' && primaryBackground,
        backgroundColor === 'secondary' && secondaryBackground,
      ]}
    >
      <Box css={linkBoxContentWrapper}>
        <Box>
          <LinkTitle title={linkTitleText} />
        </Box>
        <Box css={linkButtonStylesWraper}>
          <LinkButton buttonText={linkButtonText} handleClick={handleClick} />
        </Box>
      </Box>
    </Box>
  );
};

export default LinkBox;
